<template>
    <form-wizard
        ref="checkoutFormWizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        hide-buttons
        class="checkout-form-wizard steps-transparent"
    >
        <!-- Cart tab -->
        <tab-content title="Cart" :before-change="validateForm">
            <checkout-cart @next-step="formWizardNextStep"/>
        </tab-content>

        <!-- Delivery/Pickup -->
        <tab-content title="Checkout" :before-change="validateForm">
            <checkout-address @next-step="formWizardNextStep"/>
        </tab-content>

        <!-- Payment Methods -->
        <tab-content title="Confirmation" :before-change="validateForm">
            <checkout-payment @next-step="formWizardNextStep"/>
        </tab-content>
    </form-wizard>
</template>

<script>
    import { FormWizard, TabContent } from 'vue-form-wizard'
    import { ref } from '@vue/composition-api'
    import Cart from '../../../libs/controllers/cart-control'
    import CheckoutCart from './CheckoutCart.vue'
    import CheckoutAddress from './CheckoutAddress.vue'
    import CheckoutPayment from './CheckoutPayment.vue'

    export default {
        components: {
            FormWizard,
            TabContent,
            CheckoutCart,
            CheckoutAddress,
            CheckoutPayment,
        },
        setup() {
            const checkoutFormWizard = ref(null)
            const formWizardNextStep = () => {
                checkoutFormWizard.value.nextTab()
            }

            return {
                checkoutFormWizard,
                formWizardNextStep
            }
        },
        data() {
            return {
                cart: new Cart
            }
        },
        methods: {
            validateForm() {
                return true;
            },
            switchSlide(step) {
                console.log('Order placed from event.', step)
            }
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-wizard.scss';
    @import '~@core/scss/base/pages/app-ecommerce.scss';
    @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
    .checkout-form-wizard ::v-deep {
        .wizard-tab-content {
            box-shadow: none !important;
            background: transparent !important;
            padding: 0;
        }
    }
</style>