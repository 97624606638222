<template>
    <div class="container-fluid m-0 p-0">
        <!-- Fulfillment Method Picker -->
        <b-card no-body>
            <b-card-header class="flex-column align-items-start">
                <b-card-title>Order Fulfillment</b-card-title>
                <b-card-text class="text-secondary mt-25">
                    Select how you would like your order to be completed.
                </b-card-text>
            </b-card-header>
            <b-card-body>
                <b-form-group>
                    <b-form-radio-group @click.prevent id="fullfillment-type" name="fullfillment-type" v-model="fulfill" :options="fulfillmentOptions" inline value-field="value" text-field="text" disabled-field="disabled"/>
                </b-form-group>
            </b-card-body>
        </b-card>
        <transition appear name="fade" mode="out-in">
            <div v-if="fulfill == 'pickup'" key="card-pickup">
                <div class="d-flex flex-row flex-wrap p-0 m-0 align-items-start justify-content-center">
                    <!-- Left box -->
                    <div class="col-12 col-sm-12 col-md-6 order-1 p-0">
                        <b-card no-body>
                            <b-card-header class="flex-column align-items-start">
                                <b-card-title>Pickup</b-card-title>
                                <b-card-text class="text-secondary mt-25">
                                    <p>
                                        Once your order has been received and confirmed by us, you will be advised how long it will take to be prepared<br>
                                        and once done, you may head over to the store of your choice to pick your order up.
                                    </p>
                                </b-card-text>
                            </b-card-header>
                            <b-card-body>
                                <b-form-group>
                                    <b-form-select v-model="branchSelected" :options="branches"/>
                                </b-form-group>
                                <!-- <b-card-text class="text-secondary mt-25">
                                    <p v-if="branchSelected == ''">Select a branch to view details.</p>
                                    <div v-if="branchSelected != ''" class="d-flex flex-column flex-wrap">
                                        <div class="col-12">{{ branchData[branchSelected].address_line1 }}</div>
                                        <div class="col-12">{{ branchData[branchSelected].address_line2 }}</div>
                                        <div class="col-12">{{ branchData[branchSelected].address_line3 }}</div>
                                        <div class="col-12">{{ branchData[branchSelected].phone }}</div>
                                        <div class="col-12">{{ branchData[branchSelected].mobile }}</div>
                                    </div>
                                </b-card-text> -->
                            </b-card-body>
                        </b-card>
                    </div>

                    <!-- Right box -->
                    <div class="col-12 col-sm-12 col-md-6 order-2 p-0 pl-md-1">
                        <b-card no-body>
                            <b-card-header class="flex-column align-items-start">
                                <b-card-title>Branch Details</b-card-title>
                            </b-card-header>

                            <b-card-body>
                                <b-card-text class="text-secondary mt-25">
                                    <p v-if="branchSelected == ''">Select a branch to view details.</p>
                                    <div v-if="branchSelected != ''" class="d-flex flex-column flex-wrap">
                                        <div class="col-12 p-0">{{ branchData[branchSelected].address_line1 }}</div>
                                        <div class="col-12 p-0">{{ branchData[branchSelected].address_line2 }}</div>
                                        <div class="col-12 p-0">{{ branchData[branchSelected].address_line3 }}</div>
                                        <div class="col-12 p-0">{{ branchData[branchSelected].phone }}</div>
                                        <div class="col-12 p-0">{{ branchData[branchSelected].mobile }}</div>
                                    </div>
                                </b-card-text>
                                <div class="d-flex flex-row flex-wrap" v-if="branchSelected != ''">
                                    <div class="col-12 p-0">
                                        <b-button variant="success" block @click="$emit('next-step')">Pick this branch</b-button>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>
            </div>

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver" v-if="fulfill == 'delivery'" key="card-delivery">
                <b-form class="list-view product-checkout" @submit.prevent="handleSubmit(onSubmit)">
                    <!-- Left form -->
                    <div class="d-flex flex-row flex-wrap align-items-start">
                        <b-card no-body class="col-12 col-sm-12 col-md-12 col-lg-8 order-2 order-sm-2 order-md-2 order-lg-1">
                            <b-card-header class="flex-column align-items-start">
                                <b-card-title>Add New Address</b-card-title>
                                <b-card-text class="text-muted mt-25">
                                    Be sure to check the "Primary Address" section when you have accomplished this form.
                                </b-card-text>
                            </b-card-header>

                            <b-card-body>
                                <b-row>
                                    <!-- Address Type -->
                                    <b-col cols="12" md="12" class="mb-2">
                                        <validation-provider #default="validationContext" name="Address Type" rules="required">
                                            <b-form-group label="Address Type" label-for="address_type" :state="getValidationState(validationContext)">
                                                <!-- <v-select v-model="address.address_type" :options="addressType" input-id="address_type" :clearable="false"/> -->
                                                <v-select
                                                    v-model="address.address_type"
                                                    :options="addressTypes"
                                                    input-id="address_type"
                                                    :clearable="false"
                                                />
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- Full Name -->
                                    <b-col cols="12" md="6">
                                        <validation-provider #default="validationContext" name="Full Name" rules="required">
                                            <b-form-group label="Full Name" label-for="full-name" class="mb-2">
                                                <b-form-input v-model="fullName" id="full-name" :state="getValidationState(validationContext)" trim/>
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- Mobile number -->
                                    <b-col cols="12" md="6" class="mb-2">
                                        <validation-provider #default="validationContext" name="Mobile Number" rules="required|integer">
                                            <b-form-group label="Mobile Number" label-for="mobile">
                                                <b-form-input id="mobile" v-model="mobileNumber" type="number" :state="getValidationState(validationContext)" trim/>
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- House/Building No. -->
                                    <b-col cols="12" md="6" class="mb-2">
                                        <validation-provider #default="validationContext" name="House/Building No.">
                                            <b-form-group label="House/Building No." label-for="house-building-number" rules="required">
                                                <b-form-input id="house-building-number" :state="getValidationState(validationContext)" trim/>
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- Province -->
                                    <b-col cols="12" md="6" class="mb-2">
                                        <validation-provider #default="validationContext" name="Province">
                                            <b-form-group label="Province" label-for="province" rules="required">
                                                <b-form-select v-model="selectedProvince" :options="provinces" @change="getAddressFragments" id="province" :state="getValidationState(validationContext)"/>
                                                <!-- <b-form-input id="province" :state="getValidationState(validationContext)" trim/> -->
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- Municipality or City-->
                                    <b-col cols="12" md="6" class="mb-2">
                                        <validation-provider #default="validationContext" name="Municipality/City">
                                            <b-form-group label="Municipality/City" label-for="municipality-city" rules="required">
                                                <b-form-select v-model="selectedCity" :disabled="!selectedProvince" :options="cities" @change="getAddressFragments" id="municipality-city" :state="getValidationState(validationContext)"/>
                                                <!-- <b-form-input id="municipality-city" :state="getValidationState(validationContext)" trim/> -->
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <!-- Barangay -->
                                    <b-col cols="12" md="6" class="mb-2">
                                        <validation-provider #default="validationContext" name="Barangay">
                                            <b-form-group label="Barangay" label-for="barangay" rules="required">
                                                <b-form-select v-model="selectedBrgy" :disabled="!selectedCity" :options="barangays" id="barangay" :state="getValidationState(validationContext)"/>
                                                <!-- <b-form-input id="barangay" :state="getValidationState(validationContext)" trim/> -->
                                                <b-form-invalid-feedback>
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col cols="12">
                                        <b-button variant="primary" type="submit">Save this address and deliver here</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>

                        <!-- Right column -->
                        <div class="customer-card col-12 col-sm-12 col-md-12 col-lg-4 order-1 order-sm-1 order-md-1 order-lg-2 p-0 pl-lg-2">
                            <b-card no-body>
                                <b-card-header class="flex-column align-items-start">
                                    <b-card-title>Primary Address</b-card-title>
                                    <b-card-text class="text-muted mt-25">
                                        Your order will be delivered here if no changes to the delivery address are made.
                                    </b-card-text>
                                </b-card-header>
                                <b-card-body>
                                    <b-card-text class="mb-0">{{ user.profile.first_name }} {{ user.profile.last_name }} ({{ user.profile.mobile }})</b-card-text>
                                    <b-card-text>{{ user.address.given_address }}</b-card-text>
                                    <b-card-text>{{ user.address.address_line1 }}</b-card-text>
                                    <b-card-text>{{ user.address.address_line2 }}</b-card-text>
                                    <b-button variant="success" block @click="$emit('next-step')">
                                        Deliver to this Address
                                    </b-button>
                                </b-card-body>
                            </b-card>
                        </div>
                    </div>
                </b-form>
            </validation-observer>
        </transition>
    </div>
</template>

<script>
// checkoutAddrRef
    import {
        BForm, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormRadioGroup, BFormSelect
    } from 'bootstrap-vue'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import vSelect from 'vue-select'
    import { required, integer } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'

    export default {
        components: {
            BForm,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BButton,
            BFormInvalidFeedback,
            BFormRadioGroup,
            BFormSelect,
            // Validations
            ValidationObserver,
            ValidationProvider,
            vSelect,
        },
        props: {},
        setup(_, { emit }) {
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(() => {})

            const onSubmit = () => {
                emit('next-step')
            }

            return {
                onSubmit,
                refFormObserver,
                getValidationState,
                resetForm,
                required,
                integer
            }
        },
        mounted() {
            setTimeout(() => {
                this.getAddressFragments()
            }, 100)

            // window.localStorage.setItem('user_address', JSON.stringify(this.$store.getters.getSampleAddress))
            this.user.profile = this.$store.getters.getSampleProfile
            this.user.address = this.$store.getters.getSampleAddress
            this.fullName = this.user.profile.first_name + ' ' + this.user.profile.last_name
            this.mobileNumber = this.user.profile.mobile
        },
        data() {
            return {
                fulfill: 'select',
                address: {
                    address_type: ''
                },
                user: {},
                fullName: null,
                mobileNumber: null,
                addressTypes: ['Home', 'Office'],
                fulfillmentOptions: [
                    {text: 'Delivery', value: 'delivery', disabled: false},
                    {text: 'In-store Pickup', value: 'pickup', disabled: false},
                ],
                branches: [
                    {text: 'Select a branch', value: '', disabled: true},
                    {text: 'Tiong Bahru Valero', value: 'tbValero', disabled: false},
                    {text: 'Tiong Bahru MET LIVE', value: 'tbMetlive', disabled: false},
                ],
                branchSelected: '',
                branchData: {
                    tbValero: {
                        address_line1: 'Ground Floor, Easton Place Condominium',
                        address_line2: '118 Valero St., V.A. Rufino, Salcedo Village',
                        address_line3: 'Bel-Air 1209 Makati City',
                        phone: '(02) 8553 5291',
                        mobile: '0966 875 9907',
                    },
                    tbMetlive: {
                        address_line1: '2nd Level MET LIVE, EDSA Extension cor. Macapagal Boulevard',
                        address_line2: 'Metro Park Bay Area CBD',
                        address_line3: 'Pasay City',
                        phone: '(02) 8288 5517',
                        mobile: '0927 024 9187',
                    }
                },
                provinces: [
                    {value: null, text: 'Select a province', disabled: true}
                ],
                cities: [
                    {value: null, text: 'Select a city', disabled: true}
                ],
                barangays: [
                    {value: null, text: 'Select a barangay', disabled: true}
                ],
                // Data models
                selectedProvince: null,
                selectedCity: null,
                selectedBrgy: null,
                addrLine1: null,
                addrLine2: null,
                givenAddr: null,
                zipCode: null,
            }
        },
        computed: {

        },
        methods: {
            constructOptions(data, list) {
                if (list.length > 1) {
                    list.splice(1, list.length - 1)
                }
                data.forEach(item => {
                    let i = {value: item.id, text: item.value, disabled: false}
                    list.push(i)
                })
            },
            async getAddressFragments() {
                let data = {province: this.selectedProvince, city: this.selectedCity, brgy: this.selectedBrgy}
                this.$store.dispatch('getLocationFragment', data).then(response => {
                    let res = response.data
                    if (res.data) {
                        // Provinces
                        if (res.data.province) {
                            let prov = res.data.province
                            this.constructOptions(prov, this.provinces)

                            if (this.selectedCity && this.selectedBrgy) {
                                this.selectedCity = null
                                this.selectedBrgy = null
                            }
                        }

                        // Cities
                        if (res.data.city) {
                            let city = res.data.city
                            this.constructOptions(city, this.cities)

                            if (this.selectedBrgy) {
                                this.selectedBrgy = null
                            }
                        }

                        // Barangays
                        if (res.data.brgy) {
                            let barangay = res.data.brgy
                            this.constructOptions(barangay, this.barangays)
                        }
                    }
                    else if (!res.result && !res.data) {
                        // Assuming that the request failed, this should fall here.
                        console.log(response)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Cannot get Province listing right now',
                                icon: 'MapPinIcon',
                                variant: 'danger',
                                text: response.message
                            }
                        })
                    }
                }).catch(ex => {
                    console.log('Error dispatching getLocationFragment: ', ex)
                })
            },
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>