<template>
    <div class="col col-12 col-sm-12 col-md-4 col-lg-4 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2">
        <div class="checkout-options">
            <b-overlay :show="show" variant="transparent" blur="2px" no-wrap></b-overlay>
            <b-card>
                <div class="price-details">
                    <h6 class="price-title">Cart Summary</h6>
                    <ul class="list-unstyled">
                        <li class="price-detail">
                            <div class="detail-title">Price</div>
                            <div class="detail-amt">&#8369;{{ data.gross ? data.gross : 0 }}</div>
                        </li>
                        <li class="price-detail">
                            <div class="detail-title">+12% VAT</div>
                            <div class="detail-amt">&#8369;{{ data.vat ? data.vat : 0 }}</div>
                        </li>
                        <li class="price-detail">
                            <div class="detail-title">Discounts</div>
                            <div class="detail-amt">&#8369;{{ data.discounts ? data.discounts : 0 }}</div>
                        </li>
                        <li class="price-detail">
                            <div class="detail-title">Delivery Fee</div>
                            <div class="detail-amt"><!--&#8369;0-->{{ data.delivery > 0 ? data.delivery : 'Free'}}</div>
                        </li>
                    </ul>
                    <hr/>
                    <ul class="list-unstyled">
                        <li class="price-detail">
                            <div class="detail-title detail-total">Total</div>
                            <div class="detail-amt font-weight-bolder">&#8369;{{ data.net ? data.net : 0 }}</div>
                        </li>
                    </ul>
                    <b-button variant="success" block @click="placeOrder">
                        Checkout
                    </b-button>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
    import { BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend, BOverlay } from 'bootstrap-vue'
    export default {
        props: {
            data: Object,
            show: Boolean
        },
        components: {
            BButton,
            BCard,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BOverlay
        },
        computed: {},
        data() {
            return {}
        },
        methods: {
            placeOrder() {
                this.$parent.$emit('next-step', {from: 'cart', to: 'address'})
            }
        }
    }
</script>
<style lang="scss">
    .price-title {
        font-weight: bold;
    }

    .price-detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .detail-title {
            font-weight: 500
        }

        .detail-amt {
            font-weight: bold;
        }
    }
</style>