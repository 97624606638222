<template>
    <div class="list-view product-checkout mt-0">
        <b-overlay :show="show" variant="transparent" blur="2px" no-wrap></b-overlay>
        <div class="row d-flex flex-wrap align-items-start justify-content-between">
            <!-- Item List -->
            <checkout-cart-items v-if="items.length != 0 && details" :cartItems="items" @cart-removed-item="fetchItems" @item-qty-updated="fetchItems"/>

            <!-- Checkout Options -->
            <checkout-options v-if="items.length != 0 && details" :data="details" :show="showCartSummaryOverlay"/>
        </div>
    </div>
</template>

<script>
    import { BOverlay } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Cart from '../../../libs/controllers/cart-control'
    import CheckoutCartItems from './CheckoutCartItems.vue'
    import CheckoutOptions from './CheckoutOptions.vue'
    export default {
        components: {
            BOverlay,
            CheckoutCartItems,
            CheckoutOptions,
        },
        created() {
        },
        mounted() {
            this.fetchItems()
        },
        data()  {
            return {
                items: [],
                details: null,
                cart: new Cart,
                show: true,
                showCartSummaryOverlay: false
            }
        },
        computed: {
            vat() {
                let vatable = 0
                this.items.forEach(item => {
                    vatable += ((parseFloat(item.price) * 0.12) * parseInt(item.quantity))
                })
                return vatable
            },
            discounts() {
                return 0
            },
            grossTotal() {
                var vatable = this.vat
                let total = 0
                this.items.forEach(item => {
                    total += (parseFloat(item.price) * parseInt(item.quantity))
                })
                return total - vatable
            },
            netTotal() {
                return (this.grossTotal + this.vat) - this.discounts
            },
            deliveryFee() {
                return 0
            }
        },
        methods: {
            fetchItems() {
                this.showCartSummaryOverlay = true
                this.$store.dispatch('getCartFromWeb').then(response => {
                    if (response) {
                        this.items = this.$store.getters.getCart
                        if (this.items.length > 0) { 
                            this.computeCart()
                            this.show = false
                            this.showCartSummaryOverlay = false
                        }
                        else {
                            // Redirect to home page if the checkout page has no contents.
                            this.$router.replace('/')
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Your cart is empty',
                                    icon: 'ShoppingCartIcon',
                                    variant: 'info',
                                    text: 'Add items to your cart first.'
                                }
                            })
                        }
                    }
                    else {
                        console.log(response)
                    }
                }).catch(ex => {
                    console.log('Error dispatching getCartFromWeb action: ', ex)
                })
            },
            computeCart() {
                // Set the cart calculations.
                this.details = {
                    vat: (this.vat).toFixed(2),
                    discounts: (this.discounts).toFixed(2),
                    gross: (this.grossTotal).toFixed(2),
                    net: (this.netTotal).toFixed(2),
                    delivery: (this.deliveryFee).toFixed(2)
                }
            },
            updatedItems() {
                console.log('Hello events from CheckoutCart.')
            },
        }
    }
</script>