<template>
    <div class="container col-12 col-sm-12 col-md-8 col-lg-8 order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
        <div v-if="items.length > 0" class="container-fluid checkout-items p-0">
            <b-card no-body v-for="item in items" :key="item.id" class="ecommerce-card">
                <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <!-- Item image -->
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-4 p-0">
                        <div class="container-fluid p-0 item-thumbnail">
                            <b-img-lazy class="rounded" :src="item.image"/>
                        </div>
                    </div>
                    <!-- Item name, qty spinner -->
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-4 py-2 py-lg-0 d-flex d-lg-block align-items-center justify-content-between">
                        <div class="item-name">
                            <h6 class="mb-0">
                                <b-link class="text-body">
                                    {{ item.name }}
                                </b-link>
                            </h6>
                            <span class="item-restaurant">{{ item.brand }}</span>
                        </div>
                        <div class="item-quantity mt-lg-1">
                            <span class="quantity-title">Qty:</span>
                            <b-form-spinbutton v-model="item.quantity" @change="itemUpdated($event, item)" size="sm" inline/>
                        </div>
                    </div>
                    <!-- Item controls -->
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-4 align-items-center my-2 my-lg-0 justify-content-center">
                        <div class="item-options row align-items-center justify-content-around">
                            <div class="row item-amt-title col-6 p-0 align-items-center justify-content-center justify-content-lg-end">
                                <h3 class="m-0 mr-md-2">&#8369;{{ totalItemAmount(item.price, item.quantity) }}</h3>
                            </div>
                            <div class="row col-6 p-0 align-items-center justify-content-center justify-content-lg-end">
                                <b-button variant="danger" class="btn-icon mr-2 mx-lg-2 w-100" :id="item.id" @click.prevent="itemRemoved(item)">
                                    <feather-icon icon="Trash2Icon" />
                                    <span class="d-none d-sm-inline-block d-lg-none">Remove Item</span>
                                    <span class="d-inline-block d-sm-none">Remove</span>
                                </b-button>
                                <b-tooltip :target="(item.id).toString()" :delay="delay" placement="top">
                                    Remove {{ item.name }} from cart
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>

        <div v-if="items.length == 0" class="container-fluid checkout-items p-0">
            <div class="col col-12 justify-content-center">
                <h3 class="text-center my-4">No items to checkout.</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import {BRow, BCard, BCardBody, BLink, BImg, BImgLazy, BFormSpinbutton, BButton, BTooltip } from 'bootstrap-vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Cart from '../../../libs/controllers/cart-control'
    export default {
        props: ['cartItems'],
        components: {
            BRow,
            BCard,
            BCardBody,
            BLink,
            BImg,
            BImgLazy,
            BFormSpinbutton,
            BButton,
            BTooltip
        },
        data() {
            return {
                items: [],
                cart: new Cart,
                delay: { show: 500, hide: 200 },
                bouncedRequest: null
            }
        },
        created() {
            this.setItems()
        },
        destroyed() {
            clearTimeout(this.bouncedRequest)
        },
        computed: {
            totalAmount() {
                let total = 0
                this.items.forEach(i => { total += (i.price * i.qty) })
                return total
            },
        },
        methods: {
            setItems() {
                // Set items sent from parent component.
                this.items = this.cartItems
            },
            trimItems(item) {
                const itemIndex = this.items.findIndex(p => p.id === item.id)
                this.items.splice(itemIndex, 1)
                if (this.items.length < 1) {
                    // If the cart is empty, navigate user out of the checkout page.
                    this.$router.replace('/')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Your cart is empty',
                            icon: 'ShoppingCartIcon',
                            variant: 'info',
                            text: 'Add items to your cart first.'
                        }
                    })
                }
            },
            totalItemAmount(base, qty) {
                let amt = parseFloat(base) * parseInt(qty)
                return amt
            },
            itemUpdated(qty, item) {
                // this.cart.updateCart(item, true)

                // Delay request for 300ms before firing.
                // This is to prevent clickjacking the spinbutton.
                if (this.bouncedRequest) {
                    clearTimeout(this.bouncedRequest)
                }
                var data = {id: item.id, quantity: qty}
                this.bouncedRequest = setTimeout(() => {
                    this.$store.dispatch('updateItemInCart', data).then(response =>{ 
                        let res = response.data
                        if (res.result) {
                            this.$emit('item-qty-updated', item)
                            console.log(res.message)
                        }
                        else {
                            console.log('Error updating item qty: ', response)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Failed to adjust item quantity',
                                    icon: 'ShoppingCartIcon',
                                    variant: 'danger',
                                    text: res.message
                                }
                            })
                        }
                    }).catch(ex => {
                        console.log('Error dispatching updateItemInCart: ', ex)
                    })
                }, 300)
            },
            itemRemoved(item) {
                let data = {id: item.id}
                this.$store.dispatch('removeItemFromCart', data).then(response => {
                    let res = response.data
                    if (res.result) {
                        this.$emit('cart-removed-item')
                        // As we are already subscribed to the removeItem action,
                        // we could just use that subscription to remove items here as well.
                        this.trimItems(item)

                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Item removed from cart',
                                icon: 'ShoppingCartIcon',
                                variant: 'danger',
                                text: 'Removed ' + item.name + ' from cart.'
                            }
                        })
                    }
                    else {
                        console.log('Error removing item: ', response.message)
                    }
                }).catch(ex => {
                    console.log('Error dispatching removeItemFromCart: ', ex)
                })
            }
        }
    }
</script>

<style lang="scss">
    .item-thumbnail {
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .item-name {
        h6 {
            font-weight: bold;
        }

        a.text-body {
            transition-duration: 0.3s;
        }
    }
</style>